import * as types from '../actions/types';
var initalCart = []



const initialState = {
	cart: initalCart
}
export default function (state = initialState.cart, action) {
	switch (action.type) {
		case types.GET_CART_SUCCESS:
			return state;
		case types.ADD_TO_CART_SUCCESS:

			var exists = false;
			const newState = state.map(item => {
				if (item.cartID === action.item.cartID) {
					exists = true;
					if (action.item.isAdditionalAddon) {
						return {
							...item,
							quantity: action.item.quantity,
							price: action.item.price,
							selectedAttribute: action.item.selectedAttribute,
							sku: action.item.sku,
							addOnQty: action.item.addOnQty
						}
					} else {
						return {
							...item,
							quantity: action.item.quantity,
							price: action.item.price,
							selectedAttribute: action.item.selectedAttribute,
							sku: action.item.sku,
						}
					}
				}
				else {
					return item
				}
			});

			if (exists) {
				return newState;
			} else {
				return [
					...state,
					action.item
				];
			}
		case types.REMOVE_FROM_CART_SUCCESS:
			const remaingList = [
				...state.filter(i => i.cartID !== action.item.cartID)
			]
			return remaingList;
		case types.LOAD_CART:
			return action.item
		default:
			return state;
	}
}