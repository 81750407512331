import { createStore , applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer,persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
import logger from 'redux-logger'
const persistConfig = {
    key: 'root',
    storage,
  }
   
const persistedReducer = persistReducer(persistConfig, rootReducer)

const initialState = {};

let middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(
        applyMiddleware(...middleware),
        // window.__REDUX_DEVTOOLS_EXTENSION__() || compose
    )
 );


export default store;