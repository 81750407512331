import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Spinner from '../common/Spinner';
import { registerUser, loginUser } from '../../actions/authAction';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import SubSolutionMain from './SubSolutionMain';

const queryString = require('query-string');
class SubSolution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      mobile: '',
      password: '',
      password2: '',
      errors: {},
      emailLogin: '',
      passwordLogin: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitLogin = this.onSubmitLogin.bind(this);
  }
  componentDidMount() {}
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentWillReceiveProps(nextProps) {}

  onSubmit(e) {
    e.preventDefault();
    this.setState({ errors: {} });
    const userData = {
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      password: this.state.password,
      password2: this.state.password2,
    };
    this.props.registerUser(userData);
  }

  onSubmitLogin(e) {
    e.preventDefault();
    this.setState({ errors: {} });
    const userData = {
      emailLogin: this.state.emailLogin,
      passwordLogin: this.state.passwordLogin,
    };
    this.props.loginUser(userData);
  }
  render() {
    const { errors } = this.state;
    const { listhome, homeloading } = this.props.home;
    return (
      <div>
        {!homeloading ? (
          <React.Fragment>
            <Header
              category={listhome.category}
              subcategory={listhome.subcategory}
              subcategoryChild={listhome.subcategoryChild}
              logoUrl={listhome.setting.logoUrl}
              headerClass={'fixed-top1'}
            />

            <div className='d-flex justify-content-center align-items-center'>
              <SubSolutionMain />
            </div>

            <Footer
              footerText={listhome.setting.footerText}
              contactText={listhome.setting.contactText}
              applicationName={listhome.setting.applicationName}
              applicationEmail={listhome.setting.applicationEmail}
              contactNo={listhome.setting.contactNo}
              socialLinksList={JSON.parse(listhome.setting.socialLinksList)}
              footer1={listhome.footer1}
              footer2={listhome.footer2}
            />
          </React.Fragment>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

SubSolution.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
SubSolution.defaultpropTypes = {
  slider: [],
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  home: state.home,
});

export default connect(mapStateToProps, { registerUser, loginUser })(withRouter(SubSolution));
