import React, { useState, useEffect } from "react";
import { Container, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import image from "../../logo.svg";
import { API_URL, IMAGE_URL } from "../../actions/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const FormComponent = () => {
  const [unit, setUnit] = useState("inches");
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [roomDimensions, setRoomDimensions] = useState({
    l: 0,
    w: 0,
    h: 0,
  });
  const [deductibleDimensions, setDeductibleDimenions] = useState([]);
  const [existSpaceType, setExistSpaceType] = useState([]);
  const [showDoorCheckbox, setShowDoorCheckbox] = useState(false);
  const [showWindowCheckbox, setShowWindowCheckbox] = useState(false);
  const [walls, setWalls] = useState([]);
  const [floors, setFloors] = useState([]);
  const [ceilings, setCeilings] = useState([]);
  const [wallType, setWallType] = useState({ type: "", id: "", NRC: 0 });

  const [floorType, setFloorType] = useState({ type: "", id: "", NRC: 0 });
  const [ceilingsType, setCeilingsType] = useState({
    type: "",
    id: "",
    NRC: 0,
  });
  const [acousticTreatment, setAcousticTreatment] = useState("");
  const [sugData, setSugdata] = useState("");
  //jo
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [spaceTypeData, setSpaceTypeData] = useState([]);
  const [spaceTypeChildData, setSpaceTypeChildData] = useState([]);
  const [selectedSpaceType, setSelectedSpaceType] = useState(null);
  const [showRT60Block, setShowRT60Block] = useState(false);
  const [lengthFeet, setLengthFeet] = useState(0);
  const [lengthInches, setLengthInches] = useState(0);
  const [widthFeet, setWidthFeet] = useState(0);
  const [widthInches, setWidthInches] = useState(0);
  const [heightFeet, setHeightFeet] = useState(0);
  const [heightInches, setHeightInches] = useState(0);
  const [squareFeet, setSquareFeet] = useState(0);
  const [cubicVolume, setCubicVolume] = useState(0);
  // console.log("spaceTypeData", spaceTypeData);

  useEffect(() => {
    getWallType();
    // getSpaceType();
    // getExistingSurface();
    getFloorType();
    getCeilingType();
    getSpaceType();
    handleClickCard();
  }, []);
  useEffect(() => {
    // console.log(spaceType, "spaceType");
    console.log(wallType, "wallType");
    console.log(ceilingsType, "ceiling");
    console.log(floorType, "floors");
  }, [wallType, ceilingsType, floorType]);

  async function getFloorType() {
    try {
      const response = await axios.get(API_URL + "/api/floorType");
      console.log(response.data, "floortype");
      setFloors(response.data);
    } catch (error) {
      console.error(error, "floor type");
    }
  }

  async function getWallType() {
    try {
      const response = await axios.get(API_URL + "/api/wallType");
      console.log(response.data, "walltype1");
      setWalls(response?.data);
    } catch (error) {
      console.error(error, "wall type");
    }
  }

  async function getCeilingType() {
    try {
      const response = await axios.get(API_URL + "/api/ceilingType");
      console.log(response.data, "ceilingtype");
      setCeilings(response.data);
    } catch (error) {
      console.error(error, "ceiling type");
    }
  }

  const handleWallTypeChange = (e) => {
    const { value } = e.target;
    const dataset = walls?.find((wall) => wall._id === value);
    setWallType({
      type: dataset.type,
      id: value,
      NRC: parseFloat(dataset?.nrc),
    });
  };

  const handleFloorTypeChange = (e) => {
    const { value } = e.target;
    const dataset = floors.find((wall) => wall._id === value);
    setFloorType({
      type: dataset.type,
      id: value,
      NRC: parseFloat(dataset.nrc),
    });
  };

  const handleCeilingTypeChange = (e) => {
    const { value } = e.target;
    const dataset = ceilings.find((wall) => wall._id === value);
    setCeilingsType({
      type: dataset.type,
      id: value,
      NRC: parseFloat(dataset.nrc),
    });
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleHeightChange = (event) => {
    const inputValue = event.target.value;
    setHeight(inputValue);
    const inchesValue = unit === "inches" ? parseInt(inputValue) : parseInt(inputValue) * 12;
    setRoomDimensions((prevDimensions) => ({
      ...prevDimensions,
      h: inchesValue,
    }));
  };

  const handleWidthChange = (event) => {
    const inputValue = event.target.value;
    setWidth(inputValue);
    const inchesValue = unit === "inches" ? parseInt(inputValue) : parseInt(inputValue) * 12;
    setRoomDimensions((prevDimensions) => ({
      ...prevDimensions,
      w: inchesValue,
    }));
  };

  const handleLengthChange = (event) => {
    const inputValue = event.target.value;
    setLength(inputValue);
    const inchesValue = unit === "inches" ? parseInt(inputValue) : parseInt(inputValue) * 12;
    setRoomDimensions((prevDimensions) => ({
      ...prevDimensions,
      l: inchesValue,
    }));
  };

  // const handleFormSubmit = (event) => {
  //   event.preventDefault();
  //   // Convert feet to inches if the unit is 'feet'
  //   if (unit === "feet") {
  //     setHeight(parseInt(height) * 12);
  //     setWidth(parseInt(width) * 12);
  //     setLength(parseInt(length) * 12);
  //   }
  //   // Store room dimensions in inches in state
  //   setRoomDimensions({
  //     l: parseInt(length),
  //     w: parseInt(width),
  //     h: parseInt(height),
  //   });
  // };

  const handleLengthFeetChange = (e) => {
    const inputValue = e.target.value;


    if (inputValue === "") {
      setLengthFeet(0);
      return;
    }
    if (/^\d*$/.test(inputValue)) {
      const feetValue = parseInt(inputValue);
      if (feetValue >= 1 && feetValue <= 12000) {
        setLengthFeet(feetValue.toString());
      }
    }

  };

  const handleLengthInchesChange = (e) => {
    const inputValue = e.target.value;

    // Allow empty input (deletion)
    if (inputValue === "") {
      setLengthInches(0);
      return;
    }

    // Validate the input to allow only numbers from 1 to 12
    if (/^\d*$/.test(inputValue)) {
      const inchesValue = parseInt(inputValue);
      if (inchesValue >= 1 && inchesValue <= 12) {
        setLengthInches(inchesValue.toString());
      }
    }
  };

  const handleWidthFeetChange = (e) => {
    setWidthFeet(e.target.value);
  };

  const handleWidthInchesChange = (e) => {
    // setWidthInches(e.target.value);

    const inputValue = e.target.value;

    // Allow empty input (deletion)
    if (inputValue === "") {
      setWidthInches(0);
      return;
    }

    // Validate the input to allow only numbers from 1 to 12
    if (/^\d*$/.test(inputValue)) {
      const inchesValue = parseInt(inputValue);
      if (inchesValue >= 1 && inchesValue <= 12) {
        setWidthInches(inchesValue.toString());
      }
    }
  };

  const handleHeightFeetChange = (e) => {
    setHeightFeet(e.target.value);
  };

  const handleHeightInchesChange = (e) => {
    // setHeightInches(e.target.value);
    const inputValue = e.target.value;

    // Allow empty input (deletion)
    if (inputValue === "") {
      setHeightInches(0);
      return;
    }

    // Validate the input to allow only numbers from 1 to 12
    if (/^\d*$/.test(inputValue)) {
      const inchesValue = parseInt(inputValue);
      if (inchesValue >= 1 && inchesValue <= 12) {
        setHeightInches(inchesValue.toString());
      }
    }
  };

  const handleFormSubmit = () => {
    // Calculate the total length in inches
    // const totalLengthInSqInches = parseFloat(lengthFeet + "." + lengthInches)
    // const totalWidthInSqInches = parseFloat(widthFeet + "." + widthInches)
    // const totalHeightInSqInches = parseFloat(heightFeet + "." + heightInches)

    const totalLengthInDecimalFeet = parseInt(lengthFeet) + parseInt(lengthInches) / 12;
    // Calculate the total width in inches

    const totalWidthInDecimalFeet = parseInt(widthFeet) + parseInt(widthInches) / 12;
    // Calculate the total height in inches

    const totalHeightInDecimalFeet = parseInt(heightFeet) + parseInt(heightInches) / 12;

    const totalLengthInches = parseInt(totalLengthInDecimalFeet * 12);
    // Calculate the total width in inches

    const totalWidthInches = parseInt(totalWidthInDecimalFeet * 12);
    // Calculate the total height in inches

    const totalHeightInches = parseInt(totalHeightInDecimalFeet * 12);

    // Calculate square feet and cubic volume
    const squareFeetValue = 2 * totalWidthInches + 2 * totalLengthInches;
    const cubicVolumeValue = totalLengthInDecimalFeet * totalWidthInDecimalFeet * totalHeightInDecimalFeet;

    setSquareFeet(squareFeetValue);
    setCubicVolume(cubicVolumeValue);

    setRoomDimensions({
      l: parseInt(totalLengthInches),
      w: parseInt(totalWidthInches),
      h: parseInt(totalHeightInches),
    });
  };

  // Function to convert inches to feet and inches
  const convertToFeetAndInches = (inches) => {
    const feet = Math.floor(inches / 12);
    const remainingInches = inches % 12;
    return { feet, inches: remainingInches };
  };

  const handleDeductibleTypeChange = (index, field, e) => {
    const { value } = e.target;
    const dataset = walls.find((wall) => wall._id === value);
    setDeductibleDimenions((prevDimensions1) => {
      const updatedDimensions1 = [...prevDimensions1];
      updatedDimensions1[index][field] = parseFloat(dataset.nrc);
      return updatedDimensions1;
    });
  };

  // const handledeductibleChange = (index, field, value) => {
  //   setDeductibleDimenions((prevDimensions1) => {
  //     const updatedDimensions1 = [...prevDimensions1];
  //     updatedDimensions1[index][field] = parseFloat(value);
  //     return updatedDimensions1;
  //   });
  // };


  const handledeductibleChange = (index, field, value) => {
    // Validate the input value
    if (value === "") {
      setDeductibleDimenions((prevDimensions1) => {
        const updatedDimensions1 = [...prevDimensions1];
        updatedDimensions1[index][field] = 0;
        return updatedDimensions1;
      });
      return;
    }
    
    if (/^\d*$/.test(value)) {
      const feetValue = parseInt(value);
      if (feetValue >= 1 && feetValue <= 12000) {
        setDeductibleDimenions((prevDimensions1) => {
          const updatedDimensions1 = [...prevDimensions1];
          updatedDimensions1[index][field] = feetValue;
          return updatedDimensions1;
        });
      }
    }
  };
  

  const handleRemoveDeductible = (index) => {
    setDeductibleDimenions((prevDimensions) => {
      const updatedDimensions = [...prevDimensions];
      updatedDimensions.splice(index, 1);
      return updatedDimensions;
    });
  };

  const handleAddDeductibleWall = () => {
    setDeductibleDimenions((prevDimensions1) => [...prevDimensions1, { c: null, wf: null, wi: null, hf: null, hi: null, NRC: null }]);
  };
  const handleSubmit = () => {
    const formattedArray = deductibleDimensions.map((obj) => {
      const { wf, wi, hf, hi, c, NRC } = obj;
      // const totalWidthInInches = parseFloat(wf + "." + wi) *12
      // const totalHeightInInches = parseFloat(hf + "." + hi) * 12

      const totalWidthInDecimalFeet = parseInt(wf) + parseInt(wi) / 12;

      const totalHeightInDecimalFeet = parseInt(hf) + parseInt(hi) / 12;

      const totalWidthInInches = parseInt(totalWidthInDecimalFeet) * 12;

      const totalHeightInInches = parseInt(totalHeightInDecimalFeet) * 12;
      const area = totalWidthInInches * totalHeightInInches * c;

      return { area, nrc: NRC };
    });
    const formData = {
      space_type: selectedSpaceType,
      room_dimensions: roomDimensions,
      existing_surface: {
        wall: wallType.NRC,
        floor: floorType.NRC,
        ceiling: ceilingsType.NRC,
        allowance: formattedArray,
      },
    };
    console.log(formData, "formcheck");
    axios
      .post(API_URL + "/api/acousticTreatment", formData)
      .then((response) => {
        console.log("Response:", response.data);
        const rt60Value = response.data.data.RT60 + "";
        // Assuming "rt60" is the property name for the RT60 value
        console.log("RT60 Value:", rt60Value);
        const rt60val = rt60Value.slice(0, 6);

        setAcousticTreatment(rt60val);
        setSugdata(response.data.data.suggestedRT60);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle any errors that occurred during the request
      });

    setShowRT60Block(true);
  };
  //jo
  async function handleClickCard(data, index) {
    setHoverIndex(index);
    setSpaceTypeChildData([]); // Clear the spaceTypeChildData when collapsing the card
    if (expandedIndex === index) {
      // If the same card is clicked again, collapse it
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
      try {
        const response = await axios.get(API_URL + `/api/spacetype/category/${data?._id}`);
        setSpaceTypeChildData(response.data);
      } catch (error) {
        console.error(error, "space type");
      }
    }
  }

  async function getSpaceType() {
    try {
      const response = await axios.get(API_URL + "/api/spacetypecategory");
      setSpaceTypeData(response?.data);
    } catch (error) {
      console.error(error, "space type");
    }
  }

  const handleCheckBoxChange = (id, type) => {
    setSelectedSpaceType({ id, type });
  };

  return (
    <Container>
      <div
        style={{
          top: "15%",
          width: "100%",
          height: "100%",
          overflow: "auto",
          backgroundColor: "transparent",
        }}
        className="scroll-hide"
      >
        <h1 className="builder-header">Project Builder</h1>
        <Container className=" mb-4">
          <Row>
            <h4>Space Type</h4>
          </Row>
          <div class="cards-project-builder mt-3">
          <div class="scrollable-container">

            {spaceTypeData?.map((data, index) => {
              const isExpanded = expandedIndex === index;
              return (
                <div className="card-head" key={index}>
                  <div class="cards__item">
                    <div style={{ position: "relative" }} onClick={() => handleClickCard(data, index)}>
                      <img src={IMAGE_URL + data?.photoUrl} alt="" />
                    </div>
                    <div style={{ height: "150px" }} className={isExpanded && spaceTypeChildData.length > 0 ? "hover-container-focus" : "hover-container"}>
                      {spaceTypeChildData?.map((categoryType, idx) => (
                        <div key={idx}>
                          <Form.Check
                            style={{ paddingLeft: "3.25rem" }}
                            type="checkbox"
                            id={`checkbox-${categoryType?._id}`}
                            label={categoryType?.type}
                            checked={selectedSpaceType?.id === categoryType?._id}
                            onChange={() => handleCheckBoxChange(categoryType?._id, categoryType?.type)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}

</div>
          </div>
        </Container>
        <Container>
          <Row className="mt-2 ">
            <Col>
              <Row>
                <h4>Room Space Type</h4>
              </Row>
              <Row className="room-space-type-checkbox">
                <Col xs={4}>
                  <Form.Check type="checkbox" id="door-checkbox" label="4 Walls" checked />
                </Col>
                {/* <Col>
                  <Form.Check
                    type="checkbox"
                    id="window-checkbox"
                    label="6 Walls"
                    disabled
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    id="window-checkbox"
                    label="8 Walls"
                    disabled
                  />
                </Col> */}
                {/* ... */}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <Row>
                <h4>Wall Dimensions</h4>
              </Row>
              <Row className="cards-project-builder mt-3">
  <Col>
    <Row>
      <Col xs={12} md={6}>
        <Row className="mt-2">
          <Col xs={6} md={4}>
            <Form.Label>Length (feet):</Form.Label>
            <Form.Control type="number" value={lengthFeet} onChange={handleLengthFeetChange} />
          </Col>
          <Col xs={6} md={4}>
            <Form.Label>(inches):</Form.Label>
            <Form.Control type="number" value={lengthInches} onChange={handleLengthInchesChange} />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={6} md={4}>
            <Form.Label>Width (feet):</Form.Label>
            <Form.Control type="number" value={widthFeet} onChange={handleWidthFeetChange} />
          </Col>
          <Col xs={6} md={4}>
            <Form.Label>(inches):</Form.Label>
            <Form.Control type="number" value={widthInches} onChange={handleWidthInchesChange} />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={6} md={4}>
            <Form.Label>Height (feet):</Form.Label>
            <Form.Control type="number" value={heightFeet} onChange={handleHeightFeetChange} />
          </Col>
          <Col xs={6} md={4}>
            <Form.Label>(inches):</Form.Label>
            <Form.Control type="number" value={heightInches} onChange={handleHeightInchesChange} />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="cube-volume-row mt-5">
      <Col>
        <Row>
          <Col xs={6} md={4}>
            <h4>Total Surface Area</h4>
          </Col>
          <Col xs={6} md={4}>
            <h4>Cubic Volume</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={4} className="cube-volume m-4">
            <p>Square Feet: {squareFeet.toFixed(2)}</p>
          </Col>
          <Col xs={6} md={4} className="cube-volume m-4">
            <p>Cubic Volume: {cubicVolume.toFixed(2)}</p>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col xs={12} md={6} className="text-center">
        <Button className="mx-auto" onClick={handleFormSubmit}>
          Confirm
        </Button>
      </Col>
    </Row>
  </Col>
</Row>

            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="wall-ceiling-floor">
            <Col>
              <Row className="mt-3">
                <h4> Wall Type</h4>
              </Row>
              <Row className="mb-1 ">
                <Col xs={6}>
                  <Form.Group controlId="wallType">
                    <Form.Label>Select wall type:</Form.Label>
                    <Form.Control as="select" onChange={handleWallTypeChange}>
                      <option value="">Select</option>
                      {walls.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <h4> Floor Type</h4>
              </Row>
              <Row className="mb-1">
                <Col xs={6}>
                  <Form.Group controlId="floorType">
                    <Form.Label>Select floor type:</Form.Label>
                    <Form.Control as="select" onChange={handleFloorTypeChange}>
                      <option value="">Select</option>
                      {floors.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <h4> Ceiling Type</h4>
              </Row>
              <Row className="mb-1">
                <Col xs={6}>
                  <Form.Group controlId="ceilingType">
                    <Form.Label>Select ceiling type:</Form.Label>
                    <Form.Control as="select" onChange={handleCeilingTypeChange}>
                      <option value="">Select</option>
                      {ceilings.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="deductible-row">
            <Col>
              <Row className="">
                <h4>Window & Doors</h4>
              </Row>
              <Row>
                <Col>
                  {deductibleDimensions.map((wall, index) => (
                   <Row key={index} className="mt-4 flex-row-container">
                   <Col className="col-md-10">
                     <Row className="align-items-end">
                       <Col xs={4} className="mb-2">
                         <Form.Group controlId="wallType">
                           <Form.Label>Select door type:</Form.Label>
                           <Form.Control as="select">
                             <option value="Select">Select</option>
                             <option value="Door">Door</option>
                             <option value="Window">Window</option>
                           </Form.Control>
                         </Form.Group>
                       </Col>
                       <Col xs={4} className="mb-2">
                         <Form.Group controlId="wallType">
                           <Form.Label>Select material type:</Form.Label>
                           <Form.Control as="select" onChange={(e) => handleDeductibleTypeChange(index, "NRC", e)}>
                             <option value="">Select</option>
                             {walls.map((item) => (
                               <option key={item._id} value={item._id}>
                                 {item.type}
                               </option>
                             ))}
                           </Form.Control>
                         </Form.Group>
                       </Col>
                       <Col xs={4} className="d-flex align-items-end">
                        <Form.Group>
                         <Form.Label>Count:</Form.Label>
                         <Form.Control className="mb-0" type="number" value={wall.c} onChange={(e) => handledeductibleChange(index, "c", e.target.value)} />
                         </Form.Group>

                       </Col>
                     </Row>
                     <Row className="mt-3">
                       <Col>
                         <Row>
                           <Col xs={6} sm={4} >
                             <Form.Label>Height</Form.Label>
                             <Form.Control type="number" value={wall.hf} placeholder="feet" onChange={(e) => handledeductibleChange(index, "hf", e.target.value)} />
                           </Col>
                           <Col xs={6} sm={4} className="mb-2" style={{alignSelf:"flex-end",paddingTop:'32px'}}>
                             <Form.Control type="number" value={wall.hi} placeholder="inches" onChange={(e) => handledeductibleChange(index, "hi", e.target.value)} />
                           </Col>
                         </Row>
                         <Row>
                           <Col xs={6} sm={4} >
                             <Form.Label>Width</Form.Label>
                             <Form.Control type="number" placeholder="feet" value={wall.wf} onChange={(e) => handledeductibleChange(index, "wf", e.target.value)} />
                           </Col>
                           <Col xs={6} sm={4} className="mb-2" style={{alignSelf:"flex-end" ,paddingTop:'32px'}}>
                             <Form.Control type="number" value={wall.wh} placeholder="inches" onChange={(e) => handledeductibleChange(index, "wi", e.target.value)} />
                           </Col>
                         </Row>
                       </Col>
                     </Row>
                   </Col>
                   <Col className="col-md-2 d-flex justify-content-start align-items-center">
                     <Button variant="danger" onClick={() => handleRemoveDeductible(index)}>
                       Remove
                     </Button>
                   </Col>
                 </Row>
                 
                  ))}
                </Col>
              </Row>
              <Row>
                <Col className="ml-3 mt-2">
                  <Row className="mt-2 mb-2">
                    <Button variant="primary" onClick={handleAddDeductibleWall}>
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className="p-0 d-flex justify-content-center">
          <Button variant="primary" className="mt-4 mb-4" onClick={handleSubmit}>
            Complete
          </Button>
        </Container>
        {showRT60Block && (
          <div
            style={{
              border: "1px solid white",
              padding: "20px",
              marginTop: "20px",
            }}
          >
            <Row>
  <Col xs={12} sm={6} md={6} lg={6} xl={6} >
    <h3>Existing RT 60 Value</h3>
    <h4>{acousticTreatment}</h4>
  </Col>
  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
    <h3>Suggested RT 60 Value</h3>
    <h4>{sugData}</h4>
  </Col>
</Row>

          </div>
        )}
      </div>
    </Container>
  );
};

export default FormComponent;
