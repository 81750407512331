import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import "./SubSolutionMain.css";
import tickImg from "./img/banner-bottom-tick.png";
import { Container, Icon } from "semantic-ui-react";
import { Row, Col, Card, Button } from "react-bootstrap";
import cardImg1 from "./img/first-card-img.webp";
import cardImg2 from "./img/secont-card-img.webp";
import cardImg3 from "./img/third-card-img.webp";
import cardImg4 from "./img/fourth-card-img.jpg";
import freeShipping from "./img/free-shipping.webp";
import Assesment from "./img/assessment.webp";
import Close from "./img/close.png"
import Menu from "./img/list.svg"
import { API_URL, IMAGE_URL } from "../../actions/constant";
import axios from "axios";

function SubSolutionMain() {
  const location = useLocation();
  const [solutionData, setSolutionData] = useState([]);
  const [width, setWidth] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showCalculateModal, setShowCalculateModal] = useState(false);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleClickCalculateModal = () => {
    setShowCalculateModal(!showCalculateModal);
  };

  // http://localhost:5000/api/subcategorychild/solution/60b4e8249fbc114ef067ec62

  const getData = async () => {
    const searchParams = new URLSearchParams(location.search);
    const subcategoryChildID = searchParams.get("subcategoryChildID");
    console.log("??", subcategoryChildID);
    axios
      .get(`${API_URL}/api/subcategorychild/solution/${subcategoryChildID}`)
      .then((response) => {
        setSolutionData(response?.data);
        console.log("res", response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  function openNav() {
    document.getElementById("mySidebar").style.width = "280px";
    document.getElementById("main").style.marginLeft = "250px";
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }
  console.log("solutionData", solutionData);
  return (
    <div className="sub-solution-main">

     
          <>
            {width<1000 &&(
              <div>
                <div id="mySidebar" className="sidebar">
                  <button className="closebtn" onClick={closeNav}>
                    <img src={Close} alt="" width="26px" height="26px"/>
                  </button>
                  <Row className="p-3" style={{ width: "100%" }}>
                    <Col className="col-12">
                      <Row className="mt-4">
                        <Col className="categories-solution-head">
                          <h5>CATEGORIES</h5>
                          <ul className="mt-4">
                            <li>
                              <Link className="categories-text-link">
                                <p> WALL TREATMENT</p>
                              </Link>
                            </li>
                            <li>
                              <Link className="categories-text-link">
                                <p>CEILING TREATMENT</p>
                              </Link>
                            </li>
                            <li>
                              <Link className="categories-text-link">
                                <p>ACOUSTIC ART PANELS</p>
                              </Link>
                            </li>
                            <li>
                              <Link className="categories-text-link">
                                <p>SOUND PROOFING</p>
                              </Link>
                            </li>
                            <li>
                              <Link className="categories-text-link">
                                <p>DO-IT-YOURSELF</p>
                              </Link>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                      <Row className="mt-4 mb-2">
                        <Col className="categories-solution-head">
                          <h5>FEATURED PRODUCTS</h5>
                          <div className="featured-products-image-text">
                            <Card className="mt-4 card-feature">
                              <Card.Img src={cardImg3} />
                            </Card>
                            <p className="featured-products-text">
                              1.5" Acoustic Art Panel, Abstract F
                            </p>
                          </div>
                          <Button className="select-options-btn">
                            SELECT OPTIONS
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col className="mt-4 mb-2">
                          <Card>
                            <Card.Img src={freeShipping} />
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-4 mb-2">
                          <Card className="assessment-border">
                            <Card.Img src={Assesment} />
                          </Card>
                        </Col>
                      </Row>
                      <Row className="mt-4 mb-2">
                        <Col className="categories-solution-head mt-4">
                          <h5>HEAR THE DIFFERENCE</h5>
                          <div className="here-diff-container mt-4">
                            <p className="image-paragraph-container d-flex justify-content-center align-items-center">
                              Overtone Acoustics was founded by combining a
                              passion for sound and design. We exist to create
                              products that bring acoustical clarity to every
                              space, from home studios to commercial builds.
                              You're here because you care about the way your
                              space sounds. We are here to help.
                            </p>
                            <p className="image-paragraph-container">
                              Overtone Acoustics is well-versed in soundproofing
                              applications providing the best sound absorbing
                              acoustic panels, soundproofing materials, acoustic
                              fabrics, sound dampening curtains, and acoustic
                              ceiling tiles, baffles, and clouds.
                            </p>
                            <p className="image-paragraph-container">
                              Overtone Acoustics delivers sound solutions that
                              create more user-friendly spaces. You can trust
                              our sound deadening panels, soundproofing products
                              and services. We've designed our acoustic
                              treatments and sound proof insulation to provide
                              noise control solutions in any space.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div id="main-sideBar">
                  <button className="openbtn" onClick={openNav} >
                  <img src={Menu} width="26px" height="26px"/>
                  </button>
                </div>
              </div>)
            }
            <Row  className="sub-solution-main-child">
              <Col className="col-2 hide-content">
                <Row className="mt-4">
                  <Col className="categories-solution-head">
                    <h5>CATEGORIES</h5>
                    <ul className="mt-4">
                      <li>
                        <Link className="categories-text-link">
                          <p> WALL TREATMENT</p>
                        </Link>
                      </li>
                      <li>
                        <Link className="categories-text-link">
                          <p>CEILING TREATMENT</p>
                        </Link>
                      </li>
                      <li>
                        <Link className="categories-text-link">
                          <p>ACOUSTIC ART PANELS</p>
                        </Link>
                      </li>
                      <li>
                        <Link className="categories-text-link">
                          <p>SOUND PROOFING</p>
                        </Link>
                      </li>
                      <li>
                        <Link className="categories-text-link">
                          <p>DO-IT-YOURSELF</p>
                        </Link>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row className="mt-4 mb-2">
                  <Col className="categories-solution-head">
                    <h5>FEATURED PRODUCTS</h5>
                    <div className="featured-products-image-text">
                      <Card className="mt-4 card-feature">
                        <Card.Img src={cardImg3} />
                      </Card>
                      <p className="featured-products-text">
                        1.5" Acoustic Art Panel, Abstract F
                      </p>
                    </div>
                    <Button className="select-options-btn">
                      SELECT OPTIONS
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="mt-4 mb-2">
                    <Card>
                      <Card.Img src={freeShipping} />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4 mb-2">
                    <Card className="assessment-border">
                      <Card.Img src={Assesment} />
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-4 mb-2">
                  <Col className="categories-solution-head mt-4">
                    <h5>HEAR THE DIFFERENCE</h5>
                    <div className="here-diff-container mt-4">
                      <p className="image-paragraph-container d-flex justify-content-center align-items-center">
                        Overtone Acoustics was founded by combining a passion
                        for sound and design. We exist to create products that
                        bring acoustical clarity to every space, from home
                        studios to commercial builds. You're here because you
                        care about the way your space sounds. We are here to
                        help.
                      </p>
                      <p className="image-paragraph-container">
                        Overtone Acoustics is well-versed in soundproofing
                        applications providing the best sound absorbing acoustic
                        panels, soundproofing materials, acoustic fabrics, sound
                        dampening curtains, and acoustic ceiling tiles, baffles,
                        and clouds.
                      </p>
                      <p className="image-paragraph-container">
                        Overtone Acoustics delivers sound solutions that create
                        more user-friendly spaces. You can trust our sound
                        deadening panels, soundproofing products and services.
                        We've designed our acoustic treatments and sound proof
                        insulation to provide noise control solutions in any
                        space.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col-10 d-flex justify-content-center align-items-center">
                <section>
                  <div
                    className="banner-section"
                    style={{
                      backgroundImage: `url("${IMAGE_URL + solutionData?.photoUrl}")`,
                    }}
                  >
                    <h1 className="m-4" style={{ flexWrap: "wrap" }}>
                      {solutionData?.subCategoryChildName} {"ACOUSTICS"}
                    </h1>
                  </div>
                  <div className="banner-bottom-card">
                    <div className="banner-bottom-card-sub">
                      <img src={tickImg} alt="##" />
                      <h6>Free Acoustical Assessment</h6>
                    </div>
                  </div>
                  <Container>
                    <article>
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <p className="image-paragraph-container">
                               {solutionData?.Description}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-flex justify-content-center">
                              <span>QUESTIONS? 415-855-4950</span>
                            </Col>
                          </Row>
                          <Row className="sub-solution-card-group">
                            <Col className="mt-3 d-flex justify-content-center align-itemms-center">
                              <Card style={{ width: "13rem", height: "100%" }}>
                                <Card.Img
                                  variant="top"
                                  src={cardImg1}
                                  style={{ width: "100%", height: "13rem" }}
                                />
                                <Card.Body>
                                  <a href="##">Door Gasket set</a>
                                  <div className="card-price">
                                    <span className="old-price">$40.00</span>
                                    <span className="new-price">$35.00</span>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col className="mt-3 d-flex justify-content-center align-itemms-center">
                              <Card style={{ width: "13rem", height: "100%" }}>
                                <Card.Img
                                  variant="top"
                                  src={cardImg2}
                                  style={{ width: "100%", height: "13rem" }}
                                />
                                <Card.Body>
                                  <a href="##">Automatic Door Bottom, Basic</a>
                                  <div className="card-price">
                                    <span className="old-price">$37.00</span>
                                    <span className="new-price">$35.00</span>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col className="mt-3 d-flex justify-content-center align-itemms-center">
                              <Card style={{ width: "13rem", height: "100%" }}>
                                <Card.Img
                                  variant="top"
                                  src={cardImg3}
                                  style={{ width: "100%", height: "13rem" }}
                                />
                                <Card.Body>
                                  <a href="##">Overtone Acoustic Panel</a>
                                  <div className="card-price">
                                    <span className="old-price">$32.00</span>
                                    <span className="new-price">$30.00</span>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col className="mt-3 d-flex justify-content-center align-itemms-center">
                              <Card style={{ width: "13rem", height: "100%" }}>
                                <Card.Img
                                  variant="top"
                                  src={cardImg4}
                                  style={{ width: "100%", height: "13rem" }}
                                />
                                <Card.Body>
                                  <a href="##">Acoustic Art Panel, Custom</a>
                                  <div className="card-price">
                                    <span className="old-price">$29.00</span>
                                    <span className="new-price">$25.00</span>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                          {solutionData?.additionalDetails?.map((solutionItem, index) => (

                          <Row
                            className="flex-col-image-para"
                            style={{ marginBottom: "3%" }}
                          >
                            <Col>
                              <Row className="d-flex justify-content-center align-items-center">
                                <h3>
                                  {solutionItem.Title}
                                </h3>
                              </Row>
                              <Row
                                className=" mt-1 d-flex justify-content align-items-center"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Col
                                  className="d-flex row "
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                       <div dangerouslySetInnerHTML={{ __html:solutionItem.Description} } />
                                  {/* <p className="image-paragraph-container">
                                  {solutionItem.Description}
                                  </p> */}
                                  {/* <p className="image-paragraph-container">
                                    You’ll need to pay attention when choosing
                                    your acoustic panels, as thickness and
                                    quantity will have different effects on the
                                    sound in your studio. Acoustic panels can be
                                    used to treat issues from high frequencies
                                    (high notes on a keyboard, cymbals on a drum
                                    kit) and mid frequencies (guitar). For lower
                                    frequencies (drums, bass guitar) you’ll want
                                    to use bass traps, or thicker panels, in the
                                    corners of your studio.
                                  </p> */}

                                  <Button
                                    className="d-flex align-items-center gap-2 mr-2 ml-2"
                                    style={{ height: "50px" }}
                                  >
                                    <img
                                      className=" mr-2"
                                      src={tickImg}
                                      alt=""
                                      width="20px"
                                      height="20px"
                                    />{" "}
                                    <span>HOW MANY PANELS DO I NEED ?</span>
                                  </Button>
                                </Col>
                              </Row>
                            </Col>


                            
                            <Col className="image-solution-box-shadow m-2">
                              <img
                                className=""
                                src={ `${IMAGE_URL + solutionItem.image}`}
                                alt=""
                                width="450px"
                                height="450px"
                              />
                            </Col>
                          </Row>
))}






                          {/* <Row
                            className="flex-col-image-para2"
                            style={{ marginBottom: "3%", padding: "2%" }}
                          >
                            <Col className="image-solution-box-shadow m-2">
                              <img
                                className=""
                                src={cardImg1}
                                alt=""
                                width="450px"
                                height="450px"
                              />
                            </Col>
                            <Col>
                              <Row className="d-flex justify-content-center align-items-center">
                                <h3>SOUND-ABSORBING ACOUSTIC WALL PANELS</h3>
                              </Row>
                              <Row
                                className=" mt-1 d-flex justify-content align-items-center"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Col
                                  className="d-flex row "
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <p className="image-paragraph-container">
                                    The most popular of our acoustic sound
                                    panels for a Recording Studio is our
                                    Acoustic Fabric Panels. These are decorative
                                    cloth wrapped boards designed to capture and
                                    convert the unwelcome sound wave reflections
                                    inside your studio, and deliver back the
                                    premium sound quality you are seeking.
                                  </p>
                                  <p className="image-paragraph-container">
                                    The panels can be wall or ceiling mounted as
                                    you wish inside your space, so long as the
                                    appropriate amount of panel is produced and
                                    shipped to your project site. Acoustic
                                    Fabric Panels come in several colors, they
                                    are class A fire rated, durable, decorative,
                                    portable and combine to produce maximum
                                    sound absorption coefficients. The ideal
                                    choice for your Recording Studio.
                                  </p>

                                  <Button
                                    className="d-flex align-items-center gap-2 mr-2 ml-2"
                                    style={{ height: "50px" }}
                                  >
                                    <img
                                      className=" mr-2"
                                      src={tickImg}
                                      alt=""
                                      width="20px"
                                      height="20px"
                                    />{" "}
                                    <span>SHOP ACOUSTIC PANEL</span>
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row> */}
                          <Row className="mt-2 mb-2">
                            <Col>
                              <h3>FREQUENTLY ASKED QUESTIONS :</h3>
                            </Col>
                          </Row>
                          <Row className="content-area">
                            <Col>
                              {/* <p>
                                If your office is a large, open space with
                                minimal wall space, ceiling treatment is going
                                to be a great option for you. We have various
                                treatment options for your space. If you’re
                                treating the ceiling you’ll want to cover 30% of
                                the surface area. Acoustic ceiling tiles are
                                great for helping with sound absorption, while
                                also maintaining subtlety.
                              </p>
                              <p>
                                Another option is called suspended absorption.
                                These are products that perform very well in
                                large, open spaces with high ceilings. Suspended
                                sound absorption products work very well and
                                controlling noise levels as all of the sound
                                absorbing material is exposed to sound. Acoustic
                                Baffles are designed to suspend vertically from
                                the ceiling and have been a great solution for
                                those who are unable to use wall mounted sound
                                absorbing material.
                              </p> */}

                              {/* <h6>Here are some tips to keep in mind.</h6> */}
                              {solutionData?.faqList?.map((faqList, index) => (
                                <div key={index}>
                              <h6>
                             {faqList.question}
                              </h6>

                              <p>
                              {faqList.answer}
                              </p>
                              </div>
))}
                              {/* <h6>How Does Noise Lower Worker Productivity?</h6>
                              <p>
                                The negative impacts of noise can be very
                                significant. As the noises fluctuate in an
                                office, worker productivity can decline. People
                                will raise their voices to be heard. This will
                                increase the general noise level. In turn,
                                workers will struggle to focus and communicate
                                which increases the potential for error.
                              </p>
                              <h6>
                                Why Modern Cubicle Environments Are Less
                                Effective Due to Noise Exposure
                              </h6>
                              <p>
                                Cubicles serve as a cost-effective solution for
                                an open work space. The negative here comes from
                                the lack of office noise control. Individual
                                employees raise their voices, their neighbors
                                will raise theirs in turn. Everyone in the
                                office will suffer from this, rendering the
                                workday unproductive.
                              </p> */}
                            </Col>
                          </Row>
                          <Row className="input-areas">
                            <Col>
                              <form>
                                <label for="quantity">Length(feet)</label>
                                <input
                                  type="number"
                                  id="quantity1"
                                  name="quanti"
                                  placeholder="length"
                                />
                              </form>
                            </Col>
                            <Col>
                              <form>
                                <label for="quantity">With(feet)</label>
                                <input
                                  type="number"
                                  id="quantity2"
                                  name="quantit"
                                  placeholder="width"
                                />
                              </form>
                            </Col>
                            <Col>
                              <form>
                                <label for="quantity">Height(feet)</label>
                                <input
                                  type="number"
                                  id="quantity3"
                                  name="quan"
                                  placeholder="height"
                                />
                              </form>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="select-area">
                                <label>Floor finsh</label>
                                <select
                                  value={selectedOption}
                                  onChange={handleOptionChange}
                                >
                                  <option value="option1">Carpet</option>
                                  <option value="option2">Tile</option>
                                  <option value="option3">Concrete</option>
                                  <option value="option3">Wood</option>
                                </select>
                              </div>
                            </Col>
                            <Col>
                              <div className="select-area">
                                <label>Wall finish</label>
                                <select
                                  value={selectedOption}
                                  onChange={handleOptionChange}
                                >
                                  <option value="">Drywall</option>
                                  <option value="option1">Brick</option>
                                  <option value="option2">
                                    50% Glass / 50% Drywall
                                  </option>
                                  <option value="option3">Glass</option>
                                </select>
                              </div>
                            </Col>
                            <Col>
                              <div className="select-area">
                                <label>Ceiling finish</label>
                                <select
                                  value={selectedOption}
                                  onChange={handleOptionChange}
                                >
                                  <option value="">Drywall</option>
                                  <option value="option1">ACT</option>
                                  <option value="option2">Metal Deck</option>
                                  <option value="option3">Option 3</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {/* .........calculate.........*/}
                      <Row className="button-subsolution">
                        <Col>
                          <Button
                            className="button-child"
                            onClick={handleClickCalculateModal}
                          >
                            Calculate
                          </Button>
                        </Col>
                      </Row>
                      {/* .........calculate content......... */}
                      {showCalculateModal && (
                        <>
                          <Row
                            className="input-areas  mt-4"
                            style={{ display: "flex", alignItems: "end" }}
                          >
                            <Col>
                              <form>
                                <label for="quantity">Reverberation Time</label>
                                <input
                                  type="number"
                                  id="quantity2"
                                  name="quantit"
                                />
                              </form>
                            </Col>
                            <Col>
                              <form>
                                <label for="quantity">
                                  Recommended Reverberation Time for your Home
                                  Office
                                </label>
                                <input
                                  type="number"
                                  id="quantity3"
                                  name="quan"
                                />
                              </form>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Row className="head-acoustical-coverage mt-4 m-0 p-0">
                                <div className="acoustical-coverage">
                                  ACOUSTICAL COVERAGE BREAKDOWN FOR YOUR HOME
                                  OFFICE
                                </div>
                              </Row>
                              <Row
                                className="input-areas  mt-4"
                                style={{ display: "flex", alignItems: "end" }}
                              >
                                <Col className="m-1">
                                  <form>
                                    <label for="quantity">Basic</label>
                                    <input
                                      type="number"
                                      id="quantity2"
                                      name="quantit"
                                    />
                                  </form>
                                </Col>
                                <Col className="m-1">
                                  <form>
                                    <label for="quantity">Recommended</label>
                                    <input
                                      type="number"
                                      id="quantity3"
                                      name="quan"
                                    />
                                  </form>
                                </Col>
                              </Row>
                              <Row
                                className="input-areas  mt-4"
                                style={{ display: "flex", alignItems: "end" }}
                              >
                                <Col className="col-2 m-1">
                                  <p>1x4/2x2's :</p>
                                </Col>
                                <Col className="m-1">
                                  <form>
                                    <label for="quantity">Sqft Breakdown</label>
                                    <input
                                      type="number"
                                      id="quantity2"
                                      name="quantit"
                                    />
                                  </form>
                                </Col>
                                {"-"}
                                <Col className="m-1">
                                  <form>
                                    <label for="quantity">Sqft Breakdown</label>
                                    <input
                                      type="number"
                                      id="quantity3"
                                      name="quan"
                                    />
                                  </form>
                                </Col>
                              </Row>
                              <Row
                                className="input-areas  mt-4"
                                style={{ display: "flex", alignItems: "end" }}
                              >
                                <Col className="col-2 m-1">
                                  <p>2x4's :</p>
                                </Col>
                                <Col className="m-1">
                                  <form>
                                    <input
                                      type="number"
                                      id="quantity2"
                                      name="quantit"
                                    />
                                  </form>
                                </Col>
                                {"-"}
                                <Col className="m-1">
                                  <form>
                                    <input
                                      type="number"
                                      id="quantity3"
                                      name="quan"
                                    />
                                  </form>
                                </Col>
                              </Row>
                              <Row
                                className="input-areas  mt-4"
                                style={{ display: "flex", alignItems: "end" }}
                              >
                                <Col className="col-2 m-1">
                                  <p>4x4's :</p>
                                </Col>
                                <Col className="m-1">
                                  <form>
                                    <input
                                      type="number"
                                      id="quantity2"
                                      name="quantit"
                                    />
                                  </form>
                                </Col>
                                {"-"}
                                <Col className="m-1">
                                  <form>
                                    <input
                                      type="number"
                                      id="quantity3"
                                      name="quan"
                                    />
                                  </form>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      )}
                    </article>
                  </Container>
                </section>
              </Col>
            </Row>
          </>
       
    </div>
  );
}

export default SubSolutionMain;
