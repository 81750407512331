import React from 'react';
import './ProjectBuilder.css'
import { Link } from 'react-router-dom';
import Currency from '../common/Currency';

class StartBuildForm extends React.Component {
  render() {
    return (
      <div className='builder' >
      
      <h1 className='builder-h1'>
      Answer a few simple questions about your space, and we'll suggest a range of appropriate acoustic treatments, to suit any budget.
      </h1>

        <ul className="case-study_arro start-build">
     <h2>Start Build</h2>
                    <li  ><i className="fas fa-chevron-right" onClick={this.props.nextStep}/></li>
                </ul>
       
      </div>
    );
  }
}

class RoomType extends React.Component {
  render() {
    return (
   
<> 
<div className="ac-container attribute-container builder"  style={{padding:'15px'}}>
				{/* <input id="ac-0" name="accordion-1" type="radio" /> */}
				
					<label htmlFor="ac-0" className="acoustic-label">
					Please Select a Room Type
					</label>
				
				<article className="acoustic-article">
					<div className="row">
						<div className="col-md-12">
                        <select
									id="select-el"
									className="combo combo-input select-bg select-acoustic-panel"
									
								>
                                    <option
										className="ac-option"
										value=""
									
									>
										SELECT
									</option>

									<option
										className="ac-option"
										value=""
									
									>
										Fine Dining
									</option>
									
											<option
												className="ac-option"
												value=""
									
											>
										Casual
                                         
											</option>
                                            <option
												className="ac-option"
												value=""
									
											>
											Cafe / FastFood
                                           
											</option>
                                            <option
												className="ac-option"
												value=""
									
											>
											Pub / live Venue
                                           
											</option>
								</select>
						</div>
					</div>
				</article>
				
                
				
			</div>

<ul className="case-study_arro start-build-input">
<h2>Next</h2>
               <li  ><i className="fas fa-chevron-right" onClick={this.props.nextStep}/></li>
           </ul>

        </>

        
    
    );
  }
}

class HeightForm extends React.Component {
  render() {
    return (
   <> 
<div className="ac-container attribute-container builder"  style={{padding:'15px'}}>
				{/* <input id="ac-0" name="accordion-1" type="radio" /> */}
				
					<label htmlFor="ac-0" className="acoustic-label">
					My room height
					</label>
				
				<article className="acoustic-article">
					<div className="row">
						<div className="col-md-12">
                        <select
									id="select-el"
									className="combo combo-input select-bg select-acoustic-panel"
									
								>
                                    <option
										className="ac-option"
										value=""
									
									>
										SELECT
									</option>
                                    
									<option
										className="ac-option"
										value=""
									
									>
										18 inch
									</option>
									
											<option
												className="ac-option"
												value=""
									
											>
										24 inch
                                         
											</option>
                                            <option
												className="ac-option"
												value=""
									
											>
											32 inch
                                           
											</option>
                                            <option
												className="ac-option"
												value=""
									
											>
											40 inch
                                           
											</option>
								</select>
						</div>
					</div>
				</article>
				
                
				
			</div>

<ul className="case-study_arro start-build-input">
<h2>Next</h2>
               <li  ><i className="fas fa-chevron-right" onClick={this.props.nextStep}/></li>
           </ul>

        </>
    );
  }
}

class WidthForm extends React.Component {
  render() {
    return (
        <> 
        <div className="ac-container attribute-container builder"  style={{padding:'15px'}}>
                        {/* <input id="ac-0" name="accordion-1" type="radio" /> */}
                        
                            <label htmlFor="ac-0" className="acoustic-label">
                            My room width
                            </label>

                        <article className="acoustic-article">
                            <div className="row">
                                <div className="col-md-12">
                                <select
                                            id="select-el"
                                            className="combo combo-input select-bg select-acoustic-panel"
                                            
                                        >
                                            <option
                                                className="ac-option"
                                                value=""
                                            
                                            >
                                                SELECT
                                            </option>
                                            
                                            <option
                                                className="ac-option"
                                                value=""
                                            
                                            >
                                               24 inch
                                            </option>
                                            
                                                    <option
                                                        className="ac-option"
                                                        value=""
                                            
                                                    >
                                               36 inch
                                                 
                                                    </option>
                                                    <option
                                                        className="ac-option"
                                                        value=""
                                            
                                                    >
                                                    44 inch
                                                   
                                                    </option>
                                                    <option
                                                        className="ac-option"
                                                        value=""
                                            
                                                    >
                                                    52 inch
                                                   
                                                    </option>
                                        </select>
                                </div>
                            </div>
                        </article>
                        
                        
                        
                    </div>
        
        <ul className="case-study_arro start-build-input">
        <h2>Next</h2>
                       <li  ><i className="fas fa-chevron-right" onClick={this.props.nextStep}/></li>
                   </ul>
        
                </>
    );
  }
}

class PlaceForm extends React.Component {
    render() {
      return (
          <> 
          <div className="ac-container attribute-container builder"  style={{padding:'15px'}}>
                          {/* <input id="ac-0" name="accordion-1" type="radio" /> */}
                          
                              <label htmlFor="ac-0" className="acoustic-label">
                              Where do you want to install the Acoustic Treatment?
                              </label>
  
                          <article className="acoustic-article">
                              <div className="row">
                                  <div className="col-md-12">
                                  <select
                                              id="select-el"
                                              className="combo combo-input select-bg select-acoustic-panel"
                                              
                                          >
                                              <option
                                                  className="ac-option"
                                                  value=""
                                              
                                              >
                                                  SELECT
                                              </option>
                                              
                                              <option
                                                  className="ac-option"
                                                  value=""
                                              
                                              >
                                                 Walls only
                                              </option>
                                              
                                                      <option
                                                          className="ac-option"
                                                          value=""
                                              
                                                      >
                                                 Ceiling only
                                                   
                                                      </option>
                                                      <option
                                                          className="ac-option"
                                                          value=""
                                              
                                                      >
                                                      Walls and Ceiling
                                                     
                                                      </option>
                                                   
                                          </select>
                                  </div>
                              </div>
                          </article>
                          
                          
                          
                      </div>
          
          <ul className="case-study_arro start-build-input">
          <h2>Complete</h2>
                         <li  ><i className="fas fa-chevron-right" onClick={this.props.nextStep}/></li>
                     </ul>
          
                  </>
      );
    }
  }

  class PanalForm extends React.Component {
    render() {
      return (
          <> 
          <div className='builder-img'>

          <img
													src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png"
												
												/>
          </div>

          <div className="ac-container attribute-container builder builder-panal"  style={{padding:'15px'}}>
                          {/* <input id="ac-0" name="accordion-1" type="radio" /> */}
                          
                              <label htmlFor="ac-0" className="acoustic-label">
                             Your Acoustic Panal
                              </label>
  
                         

                          <article className="acoustic-article mt-3" >
					<div className="row">
						<div className="col-md-12">
                        <select
									id="select-el"
									className="combo combo-input select-bg select-acoustic-panel"
									
								>
                                   

									<option
										className="ac-option"
										value=""
									
									>
										Fine Dining
									</option>
									
											<option
												className="ac-option"
												value=""
									
											>
										Casual
                                         
											</option>
                                            <option
												className="ac-option"
												value=""
									
											>
											Cafe / FastFood
                                           
											</option>
                                            <option
												className="ac-option"
												value=""
									
											>
											Pub / live Venue
                                           
											</option>
								</select>
						</div>
					</div>
				</article>

                <article className="acoustic-article mt-3">
					<div className="row">
						<div className="col-md-12">
                        <select
									id="select-el"
									className="combo combo-input select-bg select-acoustic-panel"
									
								>
                                   
                                    
									<option
										className="ac-option"
										value=""
									
									>
										18 inch
									</option>
									
											<option
												className="ac-option"
												value=""
									
											>
										24 inch
                                         
											</option>
                                            <option
												className="ac-option"
												value=""
									
											>
											32 inch
                                           
											</option>
                                            <option
												className="ac-option"
												value=""
									
											>
											40 inch
                                           
											</option>
								</select>
						</div>
					</div>
				</article>

                
                <article className="acoustic-article mt-3">
                            <div className="row">
                                <div className="col-md-12">
                                <select
                                            id="select-el"
                                            className="combo combo-input select-bg select-acoustic-panel"
                                            
                                        >
                                          
                                            
                                            <option
                                                className="ac-option"
                                                value=""
                                            
                                            >
                                               24 inch
                                            </option>
                                            
                                                    <option
                                                        className="ac-option"
                                                        value=""
                                            
                                                    >
                                               36 inch
                                                 
                                                    </option>
                                                    <option
                                                        className="ac-option"
                                                        value=""
                                            
                                                    >
                                                    44 inch
                                                   
                                                    </option>
                                                    <option
                                                        className="ac-option"
                                                        value=""
                                            
                                                    >
                                                    52 inch
                                                   
                                                    </option>
                                        </select>
                                </div>
                            </div>
                        </article>
                          
                        <article className="acoustic-article mt-3">
                              <div className="row">
                                  <div className="col-md-12">
                                  <select
                                              id="select-el"
                                              className="combo combo-input select-bg select-acoustic-panel"
                                              
                                          >
                                             
                                              
                                              <option
                                                  className="ac-option"
                                                  value=""
                                              
                                              >
                                                 Walls only
                                              </option>
                                              
                                                      <option
                                                          className="ac-option"
                                                          value=""
                                              
                                                      >
                                                 Ceiling only
                                                   
                                                      </option>
                                                      <option
                                                          className="ac-option"
                                                          value=""
                                              
                                                      >
                                                      Walls and Ceiling
                                                     
                                                      </option>
                                                   
                                          </select>
                                  </div>
                              </div>
                          </article>

                          <div className="price-bar">
					<p className="strickOut">
						<Currency />{' '}
						300
					</p>
					
						<p>
							<Currency />{' '}
						290{' '}
						</p>
					
					<Link
						// to="#"
						
					>
						 BUY NOW
					</Link>{' '}
				</div>
                          
                      </div>
          
          {/* <ul className="case-study_arro start-build-input">
          <h2>Complete</h2>
                         <li  ><i className="fas fa-chevron-right" onClick={this.props.nextStep}/></li>
                     </ul> */}
          
                  </>
      );
    }
  }
class YourComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
    };
  }

  nextStep = () => {
    this.setState((prevState) => ({ step: prevState.step + 1 }));
  };

  render() {
    const { step } = this.state;

    let currentForm;

    switch (step) {
      case 1:
        currentForm = <StartBuildForm nextStep={this.nextStep} />;
        break;
      case 2:
        currentForm = <RoomType nextStep={this.nextStep} />;
        break;
      case 3:
        currentForm = <HeightForm nextStep={this.nextStep} />;
        break;
      case 4:
        currentForm = <WidthForm nextStep={this.nextStep} />;
        break;
        case 5:
            currentForm = <PlaceForm nextStep={this.nextStep} />;
            break;
            case 6:
            currentForm = < PanalForm nextStep={this.nextStep} />;
            break;
      default:
        currentForm = null;
    }
   
    return <div className="container">{currentForm}</div>;
  }
}

export default YourComponent;
