import axios from 'axios';
import {
  HOME_LOADING,
  HOME_STOPLOADING,
  LIST_HOME,
  GET_ERRORS,
} from './types';
import { API_URL } from './constant'

// Get all blog
export const listHome = () => dispatch => {
  dispatch(setHomeLoading());
  axios
    .get(API_URL + '/api/setting/home')
    .then(res => {
      console.log("before HOME: ", res.data)
      let index = res.data.category.findIndex(category => category._id == "60b4ca30c2b47f2d066cd27c")
      // res.data.category[index]._id = "61652ff0a7e2700f27a43772"
      // res.data.category[index]._id = "60b4ca30c2b47f2d066cd27c"
      // res.data.category[index].sliderStyle = 1

      index = res.data.category.findIndex(category => category._id == "60b4ca4ac2b47f2d066cd27d")
      // res.data.category[index].sliderStyle = 2
      // // res.data.category[index]._id = "61653002a7e2700f27a43773"
      // res.data.category[index]._id = "60b4ca4ac2b47f2d066cd27d"
      console.log("HOME: ", res.data)
      dispatch({
        type: LIST_HOME,
        payload: res.data
      })
    })
    .catch(err => {
      console.log("err data", err)
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })

    });
};


// Home loading
export const setHomeLoading = () => {
  return {
    type: HOME_LOADING
  };
};
export const stopHomeLoading = () => {
  return {
    type: HOME_STOPLOADING
  };
};